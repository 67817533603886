import * as React from "react"
import Logo from "../components/logo"

const IndexPage = () => {
  return (
    <main style={{"backgroundColor": "#f9f6f2"}} className="flex items-center justify-center h-screen text-center">
      <div>
        <Logo
          className="inline-block mb-12"
          width={300}
          height={30.5167}
        />
        <h1 style={{"fontFamily": "Tenor Sans"}} className="text-4xl mb-10">Az oldal feltöltés alatt áll.</h1>
        <p style={{"fontFamily": "Cinzel"}} className="text-xl max-w-2xl px-4">
          Hamarosan elkészül, addig is keressen minket <a href="https://www.facebook.com/CardiBakeryCorgis">facebook</a> oldalunkon,
          vagy e-mailen a <a href="mailto:kennel@cardibakery.hu">kennel@cardibakery.hu</a> címen.
        </p>
      </div>
    </main>
  )
}

export default IndexPage
