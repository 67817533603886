import { Link } from "gatsby"
import * as React from "react"
import LogoImage from "../images/logo.png"

export interface LogoProps {
  className: string
  width: number
  height: number
}

class Logo extends React.Component<LogoProps, {}> {
  render(): React.ReactNode {
    return (
      <Link to="/" className={this.props.className}>
        <img
          src={LogoImage}
          alt="Opcotech Logo"
          width={this.props.width}
          height={this.props.height}
        />
      </Link>
    )
  }
}

export default Logo
